import React, { useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactInfo } from '../components/ContactInfo';
import { ContactStyles } from '../components/ContactStyles';
import { ImageContainer } from '../components/ImageContainer';

export default function Contact() {
  useEffect(() => {
    document.querySelector('body').classList.add('white');
    document.querySelector('body').classList.remove('grey');
  });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        ease: 'easeIn',
      },
    },
  };

  return (
    <ContactStyles variants={container} initial="hidden" animate="show">
      <ContactImageContainer>
        <StaticImage
          src="../../static/knut-wold-contact.png"
          placeholder="blurred"
          alt="Knut Wold"
        />
      </ContactImageContainer>

      <ContactInfo>
        <Name>Knut Wold</Name>

        <ContactInfoList>
          <li>Soerum Gaard, N-2335 Stange</li>
          <li>
            Mobile: <a href="tel:+47 951 35 004">+47 951 35 004</a>
          </li>
          <li>
            Mail: <a href="mailto:knwol@online.no">knwol@online.no</a>
          </li>
        </ContactInfoList>
      </ContactInfo>
    </ContactStyles>
  );
}

const ContactImageContainer = styled(ImageContainer)`
  min-height: unset;
  img {
    object-fit: contain !important;
    max-height: 100%;
    &:hover {
      cursor: default;
    }
  }
`;

const Name = styled.h2`
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const ContactInfoList = styled.ul`
  margin: 0 0 2.6rem 0;
  padding: 0;
  list-style: none;
  line-height: 3.2rem;

  li {
    font-size: 2.4rem;

    a {
      border-bottom: 2px solid var(--grey);
      &:hover {
        border-bottom: 2px solid var(--grey);
      }
    }
  }
`;
